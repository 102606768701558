import React from 'react';
import { graphql } from 'gatsby';

import Page from '../components/Page';
import SEO from '../components/seo';
import CustomBottles from '../components/CustomBottles';
import WipeTransition from '../components/WipeTransition';

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "bottleImages/customBottle_50Cal.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "bottleImages/customBottle_jersey.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(
      relativePath: { eq: "bottleImages/customBottle_jerseyReposado.png" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(
      relativePath: { eq: "bottleImages/customBottle_liberty.png" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image5: file(relativePath: { eq: "bottleImages/customBottle_nordic.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image6: file(
      relativePath: { eq: "bottleImages/customBottle_nordicReposado.png" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const customBottlesPage = ({ data, transitionStatus }) => (
  <Page
    transitionStatus={transitionStatus}
    transitionDirection="right"
    lightBackground
  >
    <meta name="theme-color" content="#000000" />
    <SEO
      title="Liberty Call Distilling Co."
      keywords={[
        'San Diego',
        'Distilleries',
        'Craft',
        'Spirits',
        'Cocktails',
        'Distillery',
        'Whiskey',
        'Gin',
        'Rum',
        'Vodka',
        'Liquor',
        'Local',
        'California',
      ]}
    />
    <CustomBottles data={data} />
    {transitionStatus === 'entering' && (
      <WipeTransition animation="finishLeftToRight" />
    )}
  </Page>
);

export default customBottlesPage;
