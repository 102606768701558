import React, { useState, useEffect } from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';

import { Layout, SplitSection, Content } from '../components/styles/Layout';

const CustomBottleContent = styled(Content)`
  display: block;
  text-align: left;
  width: 525px;
  .hidden {
    height: 0px;
    display: hidden;
    visibility: hidden;
  }

  h3.title {
    letter-spacing: 0.15rem;
    color: ${props => props.theme.navy()};
  }
  h4 {
    margin-top: 0;
    &:hover {
      color: ${props => props.theme.maroon()};
      transition: color 0.4s;
    }
  }

  label {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    justify-items: left;
    row-gap: 8px;
    font-size: 1.2rem;
    color: ${props => props.theme.navy()};
    :focus-within {
      span.labelText {
        color: ${props => props.theme.colorThree};
        transition: color 0.4s;
      }
    }
  }

  input,
  select,
  textarea {
    width: 100%;
    background: transparent;
    border-color: ${props => props.theme.navy()};
    border-style: solid;
    border-width: 0 0 2px 0;
    padding: 0;
    outline: none;
    font-size: 1.2em;
    color: ${props => props.theme.navy()};
    border-radius: 0;
    margin: 8px 0 18px 0;
    height: 22px;
    padding-bottom: 3px;
  }

  select {
    height: 34px;
    text-indent: -1px;
    overflow: visible;
  }

  input[type='file'] {
    -webkit-appearance: none;
    -webkit-rtl-ordering: left;
    text-align: left;
    height: 36px;
  }

  input[type='file']::-webkit-file-upload-button {
    -webkit-appearance: none;
    cursor: pointer;
    border-color: ${props => props.theme.navy()};
    border-radius: 3px;
    background-color: transparent;
    color: ${props => props.theme.navy()};
    height: 3rem;
    float: right;
    margin: 0 0 8px 10px;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
    &:hover {
      background-color: ${props => props.theme.navy()};
      color: white;
    }
    transition: background-color 0.4s, color 0.4s;
  }

  textarea {
    width: 100%;
    height: 40px;
  }

  .buttonContainer {
    margin-top: 12px;
    text-align: right;
    display: flex;
    justify-content: space-between;
  }

  button#cancel-button {
    border: 2px solid red;
    color: red;
    opacity: 0.5;
    background-color: transparent;
    &:hover {
      background-color: red;
      color: white;
    }
  }

  button#submit-design-button {
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      transition: all 200s;
    }
  }

  button {
    border-radius: 5px;
    background-color: ${props => props.theme.navy()};
    border-color: transparent;
    color: white;
    padding: 1rem 2rem;
    cursor: pointer;
    letter-spacing: 0.15rem;
    &:hover {
      color: ${props => props.theme.navy()};
      background: white;
      border-color: ${props => props.theme.navy()};
    }
    transition: all 0.4s;
  }

  #design-bottle-button-container {
    margin-top: 2rem;
    button {
      background-color: ${props => props.theme.maroon()};
      width: 300px;
      height: 50px;
      &:hover {
        border-color: ${props => props.theme.maroon()};
        color: ${props => props.theme.maroon()};
        background: white;
      }
    }
  }
`;

const CustomBottleSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px);
  width: 100%;
  div.bottle-buttons-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1rem;
  }
  button.bottle-img-button {
    border: none;
    background: transparent;
    cursor: pointer;
    transition: 0.2s;
  }
  button.bottle-img-button:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    transform: scale(1.2);
  }
  p#custom-bottle-description {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-top: 2rem;
    text-align: center;
    width: 75%;
  }
`;

const StoreLink = styled.a`
  border-radius: 5px;
  background-color: white;
  padding: 1.5rem 1rem;
  height: min-content;
  min-width: 200px;
  text-align: center;
  color: ${props => props.theme.navy()};
  border: ${props => props.theme.navy()};
  border-width: 2px;
  border-style: solid;
  letter-spacing: 0.15rem;
  &:hover {
    color: white;
    background-color: ${props => props.theme.navy()};
  }
  transition: all 0.4s;
`;

const StyledInfo = styled.div`
  h3 {
    margin-bottom: 0;
  }
  hr {
    margin: 2rem 0;
  }
  p#description {
    font-size: 1.2rem;
    line-height: 2rem;
    margin-bottom: 2rem;
    text-align: justify;
  }
  width: 100%;
  div#storelink-instructions-container {
    align-items: center;
    button {
      height: 3rem;
    }
    display: flex;
    margin: 0;
    p#instructions {
      font-size: 1.2rem;
      line-height: 1.7rem;
      margin-left: 2rem;
    }
  }
`;

const CustomBottles = props => {
  const [isShowingForm, setIsShowingForm] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');
  const [color, setColor] = useState();
  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [specialInstructions, setSpecialInstructions] = useState('');
  const [images, setImages] = useState();
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [width, setWidth] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isWindowDefined, setIsWindowDefined] = useState(false);

  function handleWindowSizeChange() {
    setWidth(window?.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [isWindowDefined]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsWindowDefined(true);
      setWidth(window.innerWidth);
    }
  }, []);

  const isMobile = width <= 768;

  useEffect(() => {
    if (props.data) {
      setImages(
        Object.values(props.data).map(image => image.childImageSharp.fluid)
      );
    }
  }, [props.data]);

  useEffect(() => {
    if (!line1 || !line2 || !file || !orderNumber || !color || isSubmitted) {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }
  }, [line1, line2, file, orderNumber, color, isSubmitted]);

  useEffect(() => {
    if (file) {
      setFileName(file.fileName);
    }
  }, [file]);

  const handleOrderNumberChange = event => {
    setOrderNumber(event.target.value);
  };
  const handleColorChange = event => {
    setColor(event.target.value);
  };
  const handleLineOneChange = event => {
    setLine1(event.target.value);
  };
  const handleLineTwoChange = event => {
    setLine2(event.target.value);
  };
  const handleFileChange = async event => {
    const file = event.target.files[0],
      reader = new FileReader();

    // Prevent error when User goes to upload a different image, but hits cancel instead.
    if (!file) return;

    setFile(file);
  };

  const handleSpecialInstructionsChange = event => {
    setSpecialInstructions(event.target.value);
  };

  if (isMobile) {
    return (
      <Layout style={{ overflow: 'hidden' }}>
        <SplitSection style={{ overflow: 'hidden', marginTop: '-100px' }}>
          <CustomBottleSide>
            {images?.length && (
              <Img
                style={{ width: `100%` }}
                fluid={images[selectedImageIndex]}
                alt="Whiskey Bottle"
              />
            )}
          </CustomBottleSide>
          <div
            className="contentSide"
            style={{
              padding: '7%',
              transform: 'translateY(-200px)',
              textAlign: 'center',
            }}
          >
            <h2>Sand Carved Customizable Bottles</h2>
            <p>
              When you buy our Doublewood Bourbon or Anejo de Beto Tequila in a
              customizable bottle from our online bottle shop, you have the
              option of adding custom text and your own image to the bottle.
              After you purchase customizable bottles, return to this page from
              a computer to enter your custom details.
              <br />
              <br />
              <p
                style={{
                  fontSize: '1.2rem',
                  lineHeight: '1.3rem',
                  color: 'gray',
                }}
              >
                *Customized bottles can be engraved with a metallic silver, or
                metallic gold finish. You can add up to 2 lines of text, as well
                as an image.
              </p>
            </p>
            <div style={{ marginTop: '2rem' }}>
              <StoreLink
                id="storeLink"
                href="https://libertycall-103670.square.site/"
                target="_blank"
                rel="noopener"
                noreferrer
              >
                Online Bottle Shop
              </StoreLink>
            </div>
          </div>
        </SplitSection>
      </Layout>
    );
  }

  return (
    <Layout style={{ overflow: 'hidden' }}>
      <SplitSection>
        <CustomBottleSide>
          {images?.length && (
            <Img
              style={{ width: `600px` }}
              fluid={images[selectedImageIndex]}
              alt="Whiskey Bottle"
            />
          )}
          <div className="bottle-buttons-container">
            {images?.length &&
              images.map((img, i) => {
                return (
                  <button
                    className="bottle-img-button"
                    onClick={() => setSelectedImageIndex(i)}
                  >
                    <Img
                      style={{ width: `90px` }}
                      fluid={img}
                      alt="Whiskey Bottle"
                    />
                  </button>
                );
              })}
          </div>
          <p id="custom-bottle-description">
            Add a personal touch to a unique glass etched bottle. These bottles
            are meticulously sand carved by hand in San Diego, CA and will never
            fade or wear in the dishwasher. The bottle makes a perfect decanter
            that will last a lifetime for your favorite spirits. You can order
            these custom bottles from our online bottle shop, then enter your
            order number on this page to submit your custom text and logo.
          </p>
        </CustomBottleSide>
        <div className="contentSide" style={{ padding: '5%' }}>
          <CustomBottleContent className="content">
            <div
              className="details"
              style={{ display: `${isShowingForm ? 'contents' : 'none'}` }}
            >
              <h3 className="title" style={{ marginBottom: '0.75rem' }}>
                Custom Design
              </h3>
              <h4
                style={{
                  marginTop: '0.75rem',
                  letterSpacing: '0.15rem',
                  cursor: 'pointer',
                }}
                onClick={() => setIsShowingForm(false)}
              >
                Order #: {orderNumber}
              </h4>
              <form
                name="custom-bottle"
                method="POST"
                netlify-honeypot="address"
                data-netlify="true"
                enctype="multipart/form-data"
              >
                <p className="hidden" style={{ display: 'none' }}>
                  <label>
                    Don’t fill this out if you're human:
                    <input name="address" />
                  </label>
                </p>
                <input type="hidden" name="form-name" value="custom-bottle" />
                <label>
                  Order Number *Copy this from your order confirmation email
                  <input
                    type="text"
                    name="squareStoreOrderNumber"
                    value={orderNumber}
                    onChange={handleOrderNumberChange}
                  />
                </label>
                <label>
                  Color of Engraving
                  <select
                    name="engravingColor[]"
                    value={color}
                    onChange={handleColorChange}
                    style={{ paddingBottom: '0px' }}
                  >
                    <option value="" disabled="" selected="">
                      Choose Color
                    </option>
                    <option value="GOLD">Metallic Gold</option>
                    <option value="SILVER">Metallic Silver</option>
                  </select>
                </label>
                <label>
                  Custom Text Line One
                  <input
                    type="text"
                    name="customTextLineOne"
                    value={line1}
                    onChange={handleLineOneChange}
                  />
                </label>
                <label>
                  Custom Text Line Two
                  <input
                    type="text"
                    name="customTextLineTwo"
                    value={line2}
                    onChange={handleLineTwoChange}
                  />
                </label>
                <label>
                  Upload Custom Logo
                  <input
                    type="file"
                    name="file"
                    accept=".bmp, .gif, .jpeg, .jpg, .pdf, .png, .tif, .tiff, image/jpeg"
                    value={fileName}
                    onChange={handleFileChange}
                  />
                </label>
                <label>
                  Special Instructions
                  <textarea
                    name="specialInstructions"
                    value={specialInstructions}
                    onChange={handleSpecialInstructionsChange}
                  />
                </label>
                <div className="buttonContainer">
                  <button
                    id="cancel-button"
                    type="button"
                    onClick={() => setIsShowingForm(false)}
                  >
                    Cancel
                  </button>
                  <button
                    id="submit-design-button"
                    type="submit"
                    disabled={isSubmitDisabled}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <div style={{ display: `${isShowingForm ? 'none' : 'contents'}` }}>
              <Info
                orderNumber={orderNumber}
                onOrderNumberChange={handleOrderNumberChange}
                onBegin={() => setIsShowingForm(true)}
              />
            </div>
          </CustomBottleContent>
        </div>
      </SplitSection>
    </Layout>
  );
};

const Info = ({ onBegin }) => {
  return (
    <StyledInfo className="details">
      <h3 className="title">Custom Bottles</h3>
      <hr />
      <p id="description">
        Our Liberty Call Family takes great pride in creating the exceptional
        taste of our Double Wood Bourbon. This is a labor of love, with every
        batch being carefully tasted through each step of the aging process to
        ensure the highest quality in every bottle.
        <br />
        <br />
        It all begins with the meticulous selection of only the finest 53-gallon
        medium charred American Oak barrels, where the bourbon will first age
        for up to 3 years.
        <br />
        <br />
        While an outstanding bourbon emerges from this step alone, we decided to
        go above and beyond in pursuit of unrivaled taste. That’s why we finish
        every batch in five-gallon medium charred American Oak barrels for a
        minimum of 6 months.
        <br />
        <br />
        By exposing the bourbon to 10 times more wood, we achieve a deep amber
        in the glass. With hints of oak and spice on the nose between soft
        vanilla notes, there is a pronounced sweetness on the palate while
        showing touches of caramel and dark chocolate.
        <br />
        <br />
        Our love for this craft is evident in every sip of our bourbon and we’d
        be honored for you to share a drink with us. Enjoy responsibly.
      </p>
      <div id="storelink-instructions-container">
        <StoreLink
          id="storeLink"
          href="https://libertycall-103670.square.site/"
          target="_blank"
          rel="noopener"
          noreferrer
        >
          Online Bottle Shop
        </StoreLink>
        <p id="instructions">
          Purchase any combination of custom bottles from our online store, then
          return to this page to enter your order number and submit your custom
          details.
        </p>
      </div>
      <div id="design-bottle-button-container">
        <button type="button" onClick={onBegin}>
          Design Bottle
        </button>
      </div>
    </StyledInfo>
  );
};

export default CustomBottles;
